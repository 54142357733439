import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Helmet from "react-helmet"

export default ({ data }) => {
  const post = data.markdownRemark
  const { title, note, melody } = post.frontmatter

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} - Sitsikirja</title>
          <script defer src="https://analytics.eu.umami.is/script.js" data-website-id="495f92f8-61f4-4c3b-a03a-b46ff110275a"></script>
        </Helmet>
        {melody ? (
          <p style={{ fontStyle: "italic", fontSize: "0.8rem" }}>
            Melodia: {post.frontmatter.melody}
          </p>
        ) : null}
        {note ? (
          <p
            style={{
              fontStyle: "italic",
              fontWeight: "bold",
              fontSize: "1rem",
            }}
          >
            HUOM: {post.frontmatter.note}
          </p>
        ) : null}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        melody
        note
      }
    }
  }
`
